export const countries = {
  BRAZIL: [-14.235, -51.9253],
  REPUBLIC_OF_IRELAND: [53.4129, -8.2439],
  IRAN: [32.4279, 53.688],
  AUSTRIA: [47.5162, 14.5501],
  GERMANY: [51.1657, 10.4515],
  DENMARK: [56.2639, 9.5018],
  CAMEROON: [3.848, 11.5021],
  CZECH_REPUBLIC: [49.8175, 15.473],
  RUSSIA: [61.524, 105.3188],
  SWEDEN: [60.1282, 18.6435],
  ENGLAND: [52.3555, -1.1743],
  KOREA_REPUBLIC: [35.9078, 127.7669],
  COSTA_RICA: [9.7489, -83.7534],
  EGYPT: [26.8206, 30.8025],
  SPAIN: [40.4637, -3.7492],
  FRANCE: [46.6034, 1.8883],
  ITALY: [41.8719, 12.5674],
  SERBIA: [44.0165, 21.0059],
  COLOMBIA: [4.5709, -74.2973],
  WALES: [52.1307, -3.7837],
  URUGUAY: [-32.5228, -55.7658],
  PERU: [-9.19, -75.0152],
  UKRAINE: [48.3794, 31.1656],
  CROATIA: [45.1, 15.2],
  POLAND: [51.9194, 19.1451],
  NIGERIA: [9.082, 8.6753],
  MEXICO: [23.6345, -102.5528],
  ROMANIA: [45.9432, 24.9668],
  NETHERLANDS: [52.1326, 5.2913],
  GEORGIA: [42.3154, 43.3569],
  SCOTLAND: [56.4907, -4.2026],
  UNITED_STATES: [37.0902, -95.7129],
  ALBANIA: [41.1533, 20.1683],
  HUNGARY: [47.1625, 19.5033],
  BELGIUM: [50.8503, 4.3517],
  ALGERIA: [28.0339, 1.6596],
  ARGENTINA: [-38.4161, -63.6167],
  AUSTRALIA: [-25.2744, 133.7751],
  CANADA: [56.1304, -106.3468],
  CHILE: [-35.6751, -71.543],
  ECUADOR: [-1.8312, -78.1834],
  GREECE: [39.0742, 21.8243],
  IVORY_COAST: [7.54, -5.5471],
  JAPAN: [36.2048, 138.2529],
  MALI: [17.5707, -3.9962],
  MOROCCO: [31.7917, -7.0926],
  NORWAY: [60.472, 8.4689],
  PANAMA: [8.5379, -80.7821],
  PORTUGAL: [39.3999, -8.2245],
  QATAR: [25.3548, 51.1839],
  SENEGAL: [14.4974, -14.4524],
  SLOVAKIA: [48.669, 19.699],
  SOUTH_KOREA: [35.9078, 127.7669],
  SWITZERLAND: [46.8182, 8.2275],
  TUNISIA: [33.8869, 9.5375],
  TURKEY: [38.9637, 35.2433],
};